import * as React from "react"

import Layout from "../components/layout"
import Layouttraining from "../components/layouttraining"
import Seo from "../components/seo"

const BodyComposition = () => {
  let title = "Testösszetétel mérés felnőtteknek"
  let subTitle = "Legyen kontroll a tested felett"
  let places = [
    {
      place: "ABS Sportcentrum - Budaörs",
      date: "előre egyeztett időpontokban",
    },
    {
      place: "Fitnesz stúdió - Herceghalom",
      date: "előre egyeztett időpontokban",
    },
  ]

  let description = (
    <span>
      {" "}
      Mindenkit érdekel a saját testsúlya, hiszen meghatározza a külsőnket.{" "}
      <b>
        A testsúly mérése önmagában azonban nem ad elegendő információt. Nem
        tudjuk meg például belőle, hogy mennyi zsír és izom van a testünkben és
        azok hol helyezkednek el.{" "}
      </b>
      Márpedig a testzsír mennyisége és eloszlása, valamint az izomtömeg
      alapvetően befolyásolják az egészségünket. Attól függően, hogy milyen
      irányba szeretnéd alakítani magad - pl. fogyni szeretnél, vagy izmot
      építeni, netán csak fenntartani a jelenlegi állapotodat -, ellátlak
      tanácsokkal. Javaslatot teszek arra, hogy milyen típusú edzéseket, milyen
      gyakorisággal végezz.
    </span>
  )
  let imageSrc = "bodycomposition"

  return (
    <Layout>
      <Seo title={title} />
      <Layouttraining
        title={title}
        subTitle={subTitle}
        description={description}
        places={places}
        imageSrc={imageSrc}
      />
    </Layout>
  )
}

export default BodyComposition
